import { FC } from "react";
import { useRecordContext } from "react-admin";
import { pauseResumeInstance } from "../services/data-provider";

const ResizeButton: FC = () => {
  const record = useRecordContext();
  const uptime = record?.total_uptime;
  const inference_instance_id = record.inference_instance_id;

  const action = uptime === 0 ? "resume" : "pause";
  const label = uptime === 0 ? "Resume" : "Pause";
  const onClick = async () => {
    if (confirm(`Are you sure you want to ${action} this device?`)) {
      await pauseResumeInstance(inference_instance_id, action);
    }
  };
  return <button onClick={onClick}>{label}</button>;
};

export default ResizeButton;
